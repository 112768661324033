import React, { useEffect, useState, useCallback, Fragment, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { fromStore } from '../../selectors';
import { setHandbookPaging, fetchBlockedPhones, deleteBlockedPhoneById, fetchAllBlockedPhones, addBlockedPhones } from '../../action/handbook';
import Loader from '../utils/Loader';
import TableBlockedPhones from './TableBlockedPhones';
import Portlet from '../utils/Portlet';
import ReactPaginate from 'react-paginate';
import ErrorContainer from '../utils/ErrorContainer';
import { utils, writeFileXLSX, read } from 'xlsx';

const BlockedPhonesList = () => {
    const dispatch = useDispatch();
    const refImport = useRef();

    const { paging, count, role } = useSelector(state => ({
        paging: state.handbook.paging.blockedPhones,
        count: state.handbook.count.blockedPhones,
        role: state.user.info.role.id,
    }));

    const blockedPhones = useSelector(fromStore.blockedPhonesListSelector);
    const loading = useSelector(fromStore.loaderBlockedPhonesSelector);
    const exportLoading = useSelector(state => state.loaders.export);
    const importLoading = useSelector(state => state.loaders.import);

    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        dispatch(fetchBlockedPhones());
    }, [dispatch]);

    const setPageLimit = useCallback((e) => {
        e.preventDefault();
        const limit = Number(e.target.value);
        const updatedPaging = { start: 0, limit };
        setCurrentPage(0);
        dispatch(setHandbookPaging('blockedPhones', updatedPaging));
        dispatch(fetchBlockedPhones());
    }, [dispatch]);

    const handlePageClick = useCallback((data) => {
        const nextStart = data.selected * paging.limit;
        const updatedPaging = { ...paging, start: nextStart };
        setCurrentPage(data.selected);
        dispatch(setHandbookPaging('blockedPhones', updatedPaging));
        dispatch(fetchBlockedPhones());
    }, [dispatch, paging]);

    const handleDelete = useCallback((id) => {
        dispatch(deleteBlockedPhoneById(id))
            .then(() => {
                dispatch(fetchBlockedPhones());
            })
            .catch((error) => {
                console.error('Ошибка при удалении записи:', error);
            });
    }, [dispatch]);

    const onExport = useCallback(async () => {
        try {
            dispatch({ type: 'SET_LOADER', field: 'blockedPhonesExport', value: true });
            
            const allBlockedPhones = await dispatch(fetchAllBlockedPhones());
            
            const filterBlockedPhones = allBlockedPhones.map(item => {
                return {
                    'Телефон': item.phone,
                    'Комментарий': item.comment || ''
                }
            });
            
            const ws = utils.json_to_sheet(filterBlockedPhones);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Заблокированные телефоны");
            writeFileXLSX(wb, "Заблокированные_телефоны.xlsx");
            
            dispatch({ type: 'SET_LOADER', field: 'blockedPhonesExport', value: false });
        } catch (error) {
            console.error('Ошибка при экспорте данных:', error);
            dispatch({ type: 'SET_LOADER', field: 'blockedPhonesExport', value: false });
        }
    }, [dispatch]);

    const onImport = useCallback(async (e) => {
        if (!e.target.files[0]) return;

        const file = e.target.files[0];
        if (!file.arrayBuffer) return;

        try {
            dispatch({ type: 'SET_LOADER', field: 'blockedPhonesImport', value: true });
            
            const data = await file.arrayBuffer();
            if (!data) {
                throw new Error('Не удалось прочитать файл');
            }

            const wb = read(data);
            const ws = wb.Sheets[wb.SheetNames[0]];
            const phonesExcel = utils.sheet_to_json(ws);
            
            if (!phonesExcel || phonesExcel.length === 0) {
                throw new Error('Файл не содержит данных');
            }
            
            const phonesData = phonesExcel.map(item => ({
                phone: item['Телефон'],
                comment: item['Комментарий'] || ''
            })).filter(item => item.phone);
            
            if (phonesData.length === 0) {
                throw new Error('В файле нет действительных записей телефонов');
            }
            debugger;
            
            const blockedPhonesProcessed = await dispatch(addBlockedPhones(phonesData));
            
            swal({
                title: 'Успех',
                text: `Импорт успешно завершен. Было обработано ${phonesData.length} записей. Из них добавлено или обновлено ${blockedPhonesProcessed.length} записей.`,
                icon: "success",
                button: 'OK'
            });

        } catch (error) {
            console.error('Ошибка при импорте данных:', error);
            await swal({
                title: 'Ошибка',
                text: `Ошибка при импорте данных: ${error.data.message}`,
                icon: 'warning',
                dangerMode: true
            });
        } finally {
            dispatch({ type: 'SET_LOADER', field: 'blockedPhonesImport', value: false });
            
            if (refImport.current) {
                refImport.current.value = "";
            }
        }
    }, [dispatch]);

    const paginationComponent = useMemo(() => {
        if (blockedPhones.length === 0) return null;
        
        const pages = Math.ceil(count / paging.limit);
        return (
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <ReactPaginate
                    previousLabel={<i className="flaticon2-back"></i>}
                    nextLabel={<i className="flaticon2-next"></i>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={8}
                    pageRangeDisplayed={5}
                    forcePage={currentPage}
                    onPageChange={handlePageClick}
                    containerClassName={'kt-datatable__pager-nav'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                    previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                    nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                    activeLinkClassName="kt-datatable__pager-link--active"
                    disabledClassName="kt-datatable__pager-link--disabled"
                />
                <div className="kt-datatable__pager-info">
                    <div className="kt-datatable__pager-size">
                        <select onChange={setPageLimit} value={paging.limit} className="form-control">
                            {[10, 20, 30, 50, 100].map(size => (
                                <option key={size} value={size}>{size}</option>
                            ))}
                        </select>
                    </div>
                    <span className="kt-datatable__pager-detail">
                        Показано {paging.start + 1} - {Math.min(paging.start + paging.limit, count)} из {count}
                    </span>
                </div>
            </div>
        );
    }, [blockedPhones.length, count, currentPage, handlePageClick, paging, setPageLimit]);

    const content = useMemo(() => {
        if (loading) {
            return <div className="kt-datatable__loading"><Loader /></div>;
        }
        
        return (
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                <TableBlockedPhones role={role} items={blockedPhones} onDelete={handleDelete} />
                {paginationComponent}
            </div>
        );
    }, [loading, blockedPhones, role, handleDelete, paginationComponent]);

    return (
        <Fragment>
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container kt-container--fluid">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">Заблокированные телефоны</h3>
                    </div>
                    <div className="kt-subheader__toolbar">
                        <button 
                            onClick={onExport} 
                            className="btn btn-dark btn-icon-sm"
                            disabled={exportLoading || importLoading}
                        >
                            {exportLoading ? (
                                <span>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    {' '}Экспорт...
                                </span>
                            ) : 'Экспорт'}
                        </button>
                        <label className={`btn btn-dark btn-icon-sm ${importLoading ? 'disabled' : ''}`}>
                            <input 
                                type="file" 
                                onChange={onImport} 
                                ref={refImport} 
                                hidden
                                disabled={importLoading || exportLoading}
                            />
                            {importLoading ? (
                                <span>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    {' '}Импорт...
                                </span>
                            ) : 'Импорт'}
                        </label>
                    </div>
                </div>
            </div>
            <div className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
                <Portlet fit={true}>
                    <ErrorContainer field="blockedPhones" style={{ margin: 15 }} hasCloseButton={true} />
                    {content}
                </Portlet>
            </div>
        </Fragment>
    );
};

export default BlockedPhonesList;