import React, { useEffect, useRef, useState } from "react"
import { useOnClickOutside } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModalAction } from "../../action";
import { withRouter  } from 'react-router-dom';
import moment from "moment";
import ReactPaginate from "react-paginate";
import { fetchHistoryOrders, setHistoryOrdersPaging } from "../../action/historyOrders";
import Select from 'react-select';
import { fromStore } from "../../selectors";

const HistoryOrdersModal = () => {
    const ref = useRef();
    const dispatch = useDispatch();

    const phone = useSelector(state => state.neworders.phone);
    const historyOrders = useSelector(state => state.historyOrders);
    const filials = useSelector(state => state.handbook.filials);
    const categories = useSelector(fromStore.categoryListSelector)

    const [currentPage, setCurrentPage] = useState(0);
    const [showDetails, setShowDetails] = useState(Array(historyOrders.list.length).fill(false))

    const paymentTitle = {
        cash: 'Наличные',
        terminal: 'Терминал',
        online: 'Онлайн-оплата',
        paymented: 'Оплачен'
    };
    
    const сashTitle = {
        no: 'под расчет',
        c1000: 'сдача с 1000',
        c1500: 'сдача с 1500',
        c2000: 'сдача с 2000',
        c5000: 'сдача с 5000',
    };

    const onClose = () => dispatch(setActiveModalAction({ field: 'historyOrders', value: null }));
    
    useOnClickOutside(ref, onClose);

    const toggleDetails = (index) => {
        setShowDetails(prevShowDetails => {
          const updatedShowDetails = [...prevShowDetails];
          updatedShowDetails[index] = !updatedShowDetails[index];
          return updatedShowDetails;
        });
      };

    const handleClickCopy = (text) => () => {
        navigator.clipboard.writeText(text)
    }

    const renderTime = (order) => {
        if(order.visible_datetime)
            return order.visible_datetime;
        return order.created_at
    }

    const renderStatusLabel = (status) => {
        switch(status) {
            case 'processing': {
                return ({ value: status, label: 'В обработке' });                    
            }
            case 'not-confirmed': {
                return({ value: status, label: 'Не подтвержден' });
            }
            case 'confirmed': {
                return ({ value: status, label: 'Подтвержден' });
            }
            case 'cook': {
                return ({ value: status, label: 'Готовится' });
            }
            case 'cooked': {
                return ({ value: status, label: 'Приготовлен' });
            }
            case 'delivery': {
                return ({ value: status, label: 'В пути' });
            }
            case 'done': {
                return ({ value: status, label: 'Выполнен' });
            }
            case 'written-off': {
                return ({ value: status, label: 'Списан' });
            }
            case 'cancel': {
                return ({ value: status, label: 'Отменен' });
            }
            case 'return': {
                return ({ value: status, label: 'Возврат' });
            }
            case 'deferred': {
                return ({ value: status, label: 'Отложенный' });
            }
            case 'new': {
                return { value: status, label: 'Новый' }
            }
            default: {
                return { value: null, label: 'Не определен' }
            }
        }
    }

    const renderStatus = (item) => {
        return <div className='order_table_selector'>
                    <Select
                        isDisabled={true}
                        value={renderStatusLabel(item.status)}
                        closeMenuOnSelect={true}
                        classNamePrefix='order_table_status_select'
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 })
                        }}
                        menuPlacement="auto"
                        options={[
                            { value: 'new', label: 'Новый' },
                            { value: 'processing', label: 'В обработке' },
                            { value: 'not-confirmed', label: 'Не подтвержден' },
                            { value: 'confirmed', label: 'Подтвержден' },
                            { value: 'cook', label: 'Готовится' },
                            { value: 'cooked', label: 'Приготовлен' },
                            { value: 'delivery', label: 'В пути' },
                            { value: 'done', label: 'Выполнен' },
                            { value: 'written-off', label: 'Списан' },
                            { value: 'cancel', label: 'Отменен' },
                            { value: 'return', label: 'Возврат' },
                            { value: 'deferred', label: 'Отложенный' }
                        ]}                                     
                        placeholder="Статус"
                        noOptionsMessage={() => 'Статусы не найдены'}
                    />  
                </div>
    }

    const renderWok = (good, index, isAggPrice) => {
        return (
            <>
            <tr key={index} className='order_table-order-card'>
                <td>{good.wokData.main.title}</td>
                <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{good.count}</td>
                <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{good.wokData.main.price}</td>            
            </tr>
            {good.wokData.meat && Object.values(good.wokData.meat).map((el) =>
                <tr key={el.id + index} className='order_table-order-card'>
                    <td className='order_table-order-card__name'>{'--' + el.data.title}</td>               
                    <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{el.count}</td>
                    <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{el.data.price}</td>
                </tr>
            )}
            {good.wokData.topping && Object.values(good.wokData.topping).map((el) =>
                <tr key={el.id + index} className='order_table-order-card'>
                    <td className='order_table-order-card__name'>{'--' + el.data.title}</td>               
                    <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{el.count}</td>
                    <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{el.data.price}</td>
                </tr>
            )}
            {good.wokData.souce && Object.values(good.wokData.souce).map((el) =>
                <tr key={el.id + index} className='order_table-order-card'>
                    <td className='order_table-order-card__name'>{'--' + el.title}</td>               
                    <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{el.count}</td>
                    <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{el.price}</td>                    
                </tr>
            )}
            </>
        );
    }

    const renderGoodTitle = (good) => {
        if (good.categoryName) {
            if (good.categoryName.toLowerCase() === 'пицца') {
                return `${good.data.title} ${good.isMod ? '36 см' : '30 см' }`                
            }
            return good.data.title
        }
        const category = categories.find(o => o.id === good.category);
        if (!category) return good.data.title;
        if (category.title.toLowerCase() === 'пицца') {
            return( `${good.data.title} ${good.isMod ? '36 см' : '30 см' }`
            );
        }
        return good.data.title
    }

    const renderGoods = (good, index, isAggPrice) => {
        if (good.isWok) return renderWok(good, index, isAggPrice);

        return (
            <tr key={index} className='order_table-order-card'>
                <td>
                    <div className='order_table-order-card__name'>{renderGoodTitle(good)}</div>
                </td>
                <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{good.count}</td>
                <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{isAggPrice ? good.oldPrice : good.price}</td>                  
            </tr>
        );  
    }

    const renderSalePrice = (salePrice, bonusCount) => {
        let saleCheck = 0

        if(salePrice && salePrice > 0) 
            saleCheck = saleCheck + salePrice;
        

        if(bonusCount && bonusCount > 0) 
            saleCheck = saleCheck + bonusCount;
        

        return saleCheck;
    }

    const renderPointTime = (point, zone) => {
        let deliveryTime = 60;
        let samTime = 20;

        if(!point && !zone) return '----';

        if(point) {
            if(point.activate === 'disabledAll')
                return 'СТОП';

            if(point.activate === 'disabledDelivery') {
                deliveryTime = 'СТОП';
            } else {
                deliveryTime = point.minDeliveryTime;
            }

            if(point.activate === 'disabledOwn') {
                samTime = 'СТОП';
            } else {
                samTime = point.minSamTime;
            }

            return `${deliveryTime}/${samTime}`;
        }

        if(zone) {
            if(zone.point.id) {
                if(zone.point.activate === 'disabledAll')
                    return 'СТОП';
    
                if(zone.point.activate === 'disabledDelivery') {
                    deliveryTime = 'СТОП';
                } else {
                    deliveryTime = zone.point.minDeliveryTime;
                }
    
                if(zone.point.activate === 'disabledOwn') {
                    samTime = 'СТОП';
                } else {
                    samTime = zone.point.minSamTime;
                }
    
                return `${deliveryTime}/${samTime}`;
            } else {
                const chekPoint = filials.find((el) => el.id === zone.point);

                if(chekPoint.activate === 'disabledAll')
                    return 'СТОП';
    
                if(chekPoint.activate === 'disabledDelivery') {
                    deliveryTime = 'СТОП';
                } else {
                    deliveryTime = chekPoint.minDeliveryTime;
                }
    
                if(chekPoint.activate === 'disabledOwn') {
                    samTime = 'СТОП';
                } else {
                    samTime = chekPoint.minSamTime;
                }
    
                return `${deliveryTime}/${samTime}`;
            }
        }

        return 'Не опредлено';
    }

    const renderAdress = (item) => {
        if (!item.street && !item.point) 
        {
            if(!item.city)
                return '-';
            if(item.city)
                return `${item.city.name}`
        }

        if(item.deliveryMethod === "delivery")
            return `${item.city ? item.city.name : ''}${item.area ? ', ' + item.area : ''}${item.street ? ', ' + item.street : ''}${item.house ? ', ' + item.house : ''}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.floor ? `, этаж ${item.floor}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
        
        if(item.deliveryMethod === "own")
            return `${item.city ? item.city.name : ''}${item.point.street ? ', ' + item.point.street: ''}${item.point.house ? ', ' + item.point.house : ''}`;
    }

    const renderPointAdress = (item) => {
        return `${item.city ? item.city.name + ', ' : ''}${item.point ? `${item.point.street ? item.point.street : ''}${item.point.house ? ', ' + item.point.house : ''}` : 'Не определен'} ${renderPointTime(item.point, item.zone)}`;
    }

    const getStatusPay = (order) => {
        if (!order.paymentId) return 'не оплачен';
        
        let paymentData = order.paymentData ? JSON.parse(order.paymentData) : null;
        
        if (!order.paymentData) return 'не оплачен';

        if (paymentData.status === 'pending') {
            return 'ожидает оплаты';
        }
      
        if (paymentData.status === 'succeeded') 
            return 'оплачен';
        
        if (paymentData.status === 'refundPending') 
            return 'выполняется возврат средств';

        if (paymentData.status === 'refund') 
            return 'возврат средств выполнен';

        if (paymentData.status === 'canceled') {
            if(paymentData.cancellation_details && paymentData.cancellation_details.party) {
                const initiator = paymentData.cancellation_details.party === 'yoo_money' ? 'ЮКасса' : 'участник процесса';
                return `возврат средств отменен, инициатор ${initiator}`;
            }
            return 'возврат средств отменен';
        }

        return 'cтатус не определен';
    }

    useEffect(() => {
        dispatch(fetchHistoryOrders(phone));
    }, [dispatch, historyOrders.paging, phone]);

    const setPageLimit = (e) => {
        e.preventDefault();
        const updatedPaging = { start: 0, limit: e.target.value};
        setCurrentPage(0);
        dispatch(setHistoryOrdersPaging(updatedPaging));
    };
    
    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
        let nextStart =  data.selected * historyOrders.paging.limit;
        const updatedPaging = {...historyOrders.paging, start: nextStart};
        dispatch(setHistoryOrdersPaging(updatedPaging));
    };

    const chekMarginPagesDisplayed = () => {
        const userDisplayWidth = window.innerWidth;

        if(userDisplayWidth < 400)
            return 1;

        if(userDisplayWidth < 550)
            return 2;

        if(userDisplayWidth < 850)
            return 3;

        if(userDisplayWidth < 1250)
            return 5;

        return 8;
    }

    const chekPageRangeDisplayed = () => {
        const userDisplayWidth = window.innerWidth;

        if(userDisplayWidth < 550)
            return 2;

        if(userDisplayWidth < 850)
            return 3;

        return 5;
    }

    const renderPagination = () => {
        const count = historyOrders.count
        const paging = historyOrders.paging
        const pages = Math.ceil(+count / +paging.limit);

        return (
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <ReactPaginate
                    previousLabel={<i className="flaticon2-back"></i>}
                    nextLabel={<i className="flaticon2-next"></i>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={chekMarginPagesDisplayed()}
                    pageRangeDisplayed={chekPageRangeDisplayed()}
                    forcePage={currentPage}
                    onPageChange={handlePageClick}
                    containerClassName={'kt-datatable__pager-nav'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                    previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                    nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                    activeLinkClassName="kt-datatable__pager-link--active"
                    disabledClassName="kt-datatable__pager-link--disabled"
                />
                    <div className="kt-datatable__pager-info">
                        <div className="kt-datatable__pager-size">
                            <select onChange={setPageLimit} value={paging.limit} className="form-control">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            
                        </div>
                        {
                            paging.start + paging.limit >= count ? (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+count} из {count}</span>
                            ) : (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+paging.start + +paging.limit} из {count}</span>
                            )
                        }
                       
                    </div>
            </div>
    
        );
    }

    const renderBody = () => {
        return(
            <tbody className="kt-datatable__body order_table"> 
            {
                historyOrders.list.map((item, orderIndex) => {
                    return (
                        <tr key={item.id} className={`kt-datatable__row order_table ${item.status}`}>
                        <thead style={{ borderBottom: '1px solid #f2f3f8' }}>
                            <th className={`kt-indicator top ${item.status}`}></th>
                            <th 
                            onDoubleClick={((event) => {
                                event.stopPropagation()
                            })}                                
                            >
                                <div className='order_table_head'>
                                    <div style={{ position: 'absolute', display: 'flex', gap: '5px', alignItems: 'center'}}>
                                        <div>
                                            <span 
                                            style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
                                            >
                                                <span>{moment(renderTime(item)).format('HH:mm')}</span>
                                                <span>{moment(renderTime(item)).format('DD.MM.YYYY')}</span>
                                                <span>{'№ ' + item.id}</span>
                                            </span>                                                    
                                        </div>
                                        <button 
                                        className='order_table_arrow-show-details'
                                        onDoubleClick={((event) => {
                                            event.stopPropagation()
                                        })}   
                                        onClick={() => {
                                            toggleDetails(orderIndex)
                                        }}                   
                                        >                 
                                            <i className={`kt-menu__ver-arrow la la-angle-down${showDetails[orderIndex] ? ' Showed' : ''}`}></i>
                                        </button>
                                        <button className='button_order_table' onClick={handleClickCopy(moment(renderTime(item)).format('HH:mm') + ' ' + moment(renderTime(item)).format('DD.MM.YYYY') + '(№ ' + item.id + ')')}>
                                            <i className='kt-nav__link-icon flaticon2-copy order_table'/>
                                        </button>
                                        {item.deliveryMethod === 'own' ? <span className='indicator_deliveryMethod'>Вынос</span> : <span className='indicator_deliveryMethod'>Доставка</span>}
                                        {item.isAggPrice && <span className='indicator_deliveryMethod'>ЯЕ</span>}
                                        {item.source !== 'crm' ? item.isSite ? <span className='indicator_deliveryMethod'>Сайт</span> : <span className='indicator_deliveryMethod'>Приложение</span> : ''}
                                    </div>
                                </div>                  
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>{renderStatus(item)}</th>
                        </thead>

                        <tr>
                            <td className={`kt-indicator bottom ${item.status}`}></td>  
                            <td className='kt-datatable__cell order_table positions'>
                                {!showDetails[orderIndex] && <button 
                                className='order_table_button-show-details'
                                onDoubleClick={((event) => {
                                    event.stopPropagation()
                                })}   
                                onClick={() => {
                                    toggleDetails(orderIndex)
                                }}
                                >
                                    Показать состав
                                </button>}
                            
                            {
                                showDetails[orderIndex] && 
                                <table>
                                    {item.goods.map((good, index) => renderGoods(good, index, item.isAggPrice))}           
                                </table>                                   
                            }
                            {
                                showDetails[orderIndex] && item.unit &&
                                <tr className='order_table-order-card'>
                                    <td>
                                        <div className='order_table-order-card__name'>Приборы</div>
                                    </td>
                                    <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{item.unit}</td>
                                    <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>0</td>                     
                                </tr>
                            }
                        </td>

                        <td className='kt-datatable__cell order_table total_price'>
                            <span style={{ color: '#000', display: 'flex', justifyContent: 'center' }}>{item.totalPrice}</span>
                        </td>

                        <td className='kt-datatable__cell order_table total_price'>
                            <span style={{ color: '#000', display: 'flex', justifyContent: 'center' }}>{renderSalePrice(parseFloat(item.salePrice), parseFloat(item.bonusCount))}</span>
                        </td>

                        <td className='kt-datatable__cell order_table payment'>
                            <span style={{ color: '#000', display: 'flex', justifyContent: 'center'  }}>
                                {`${paymentTitle[item.payment]}${(item.payment === 'cash' && item.changeCash) ? ', ' + сashTitle[item.changeCash] : ''}${item.payment === 'online' ? ', ' + getStatusPay(item) : ''}`}
                            </span>
                        </td>
                                                            
                        <td className='kt-datatable__cell order_table comment'>
                            <span style={{ color: '#ff6969' }}>{renderPointAdress(item)}</span>
                            <span style={{ width: '100%', display: 'flex', gap: '5px', alignItems: 'center', color: '#000'  }}>                                  
                                {renderAdress(item)}
                                {item.comment ? ', Комментарий: ' + item.comment : ''} {item.deliveryTime ? `, Заказ ко времени: ${moment(item.deliveryTime).format('DD.MM.YYYY в HH:mm')}` : ''}
                                <button className='button_order_table' onClick={handleClickCopy(String((item.city ? item.city.name : '') + (item.area ? ', ' + item.area : '') + (item.street ? ', ' + item.street : '') + (item.house ? ', ' + item.house : '')))}>
                                    <i className='flaticon2-copy order_table'/>
                                </button>
                            </span>
                        </td>
                        </tr>                            
                    </tr>
                    )
                })
            }
            </tbody>
        )
    }

    const renderBodyMobile = () => {        
        return(
            <tbody className="kt-datatable__body order_table">
                {
                    historyOrders.list.map((item, orderIndex) => {  
                        return(
                        <div className={`kt-datatable__row order_table ${item.status}`} style={{display: 'flex'}}>
                            <div className={`kt-indicator all ${item.status}`}/>
                            <div className='order_table_mobile_main'>
                                <div className='order_table_mobile_head'>
                                    <div className='order_table_head'>
                                        <span style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                            <span>{moment(renderTime(item)).format('HH:mm')}</span>
                                            <span>{moment(renderTime(item)).format('DD.MM.YYYY')}</span>
                                            <span>{'№ ' + item.id}</span>
                                        </span>
                                        <button 
                                        className='order_table_arrow-show-details'
                                        onDoubleClick={((event) => {
                                            event.stopPropagation()
                                        })}   
                                        onClick={() => {
                                            toggleDetails(orderIndex)
                                        }}                   
                                        >                 
                                            <i className={`kt-menu__ver-arrow la la-angle-down${showDetails[orderIndex] ? ' Showed' : ''}`}></i>
                                        </button>
                                        <button className='button_order_table' onClick={handleClickCopy(moment(renderTime(item)).format('HH:mm') + ' ' + moment(renderTime(item)).format('DD.MM.YYYY') + '(№ ' + item.id + ')')}>
                                            <i className='kt-nav__link-icon flaticon2-copy order_table'/>
                                        </button>
                                        {item.deliveryMethod === 'own' ? <span className='indicator_deliveryMethod'>Вынос</span> : <span className='indicator_deliveryMethod'>Доставка</span>}
                                        {item.isAggPrice && <span className='indicator_deliveryMethod'>ЯЕ</span>}
                                        {item.source !== 'crm' ? item.isSite ? <span className='indicator_deliveryMethod'>Сайт</span> : <span className='indicator_deliveryMethod'>Приложение</span> : ''}
                                    </div>
                                    <div style={{ minWidth: '120px'}}>
                                        {renderStatus(item)}
                                    </div>
                                </div>
                                <div className='order_table_mobile_row'>
                                    <div className='order_table_mobile positions'>
                                    {!showDetails[orderIndex] && <button 
                                        className='order_table_button-show-details'
                                        onDoubleClick={((event) => {
                                            event.stopPropagation()
                                        })}   
                                        onClick={() => {
                                            toggleDetails(orderIndex)
                                        }}
                                        >
                                            Показать состав
                                        </button>}
                                    
                                    {
                                        showDetails[orderIndex] && 
                                        <table style={{width: '100%'}}>
                                            {item.goods.map((good, index) => renderGoods(good, index, item.isAggPrice))}           
                                        </table>                                   
                                    }
                                    {
                                        showDetails[orderIndex] && item.unit &&
                                        <table style={{width: '100%'}}>
                                            <tr className='order_table-order-card' style={{ padding: 0, width: '100%'}}>
                                                <td>
                                                    <div className='order_table-order-card__name'>Приборы</div>
                                                </td>
                                                <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{item.unit}</td>
                                                <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>0</td>                     
                                            </tr>
                                        </table>
                                    }
                                    </div>
                                    <div className="order_table_mobile total_price">
                                        <span style={{ color: '#000', display: 'flex', justifyContent: 'center' }}>{item.totalPrice}</span>
                                    </div>

                                    <div className="order_table_mobile total_price">
                                        <span style={{ color: '#000', display: 'flex', justifyContent: 'center' }}>{renderSalePrice(parseFloat(item.salePrice), parseFloat(item.bonusCount))}</span>
                                    </div>
                                </div>
                                <div className='order_table_mobile_row'>
                                    <div className="order_table_mobile payment">
                                        <span style={{ color: '#000', display: 'flex', justifyContent: 'center'  }}>
                                            {`${paymentTitle[item.payment]}${(item.payment === 'cash' && item.changeCash) ? ', ' + сashTitle[item.changeCash] : ''}${item.payment === 'online' ? ', ' + getStatusPay(item) : ''}`}
                                        </span>
                                    </div>
                                </div> 
                                <div className='order_table_mobile_row'>
                                    <div className="order_table_mobile payment">
                                        <span style={{ color: '#000', display: 'flex', justifyContent: 'center' }}> {renderPointAdress(item)}</span>
                                    </div>
                                </div>                                                               
                                <div className='order_table_mobile_row'>
                                    <div className='order_table_mobile comment'>
                                        <span style={{ width: '100%', display: 'flex', gap: '5px', alignItems: 'center', color: '#000'  }}>                                     
                                            {renderAdress(item)}
                                            {item.comment ? ', Комментарий: ' + item.comment : ''} {item.deliveryTime ? `, Заказ ко времени: ${moment(item.deliveryTime).format('DD.MM.YYYY в HH:mm')}` : ''}
                                            <button className='button_order_table' onClick={handleClickCopy(String((item.city ? item.city.name : '') + (item.area ? ', ' + item.area : '') + (item.street ? ', ' + item.street : '') + (item.house ? ', ' + item.house : '')))}>
                                                <i className='flaticon2-copy order_table'/>
                                            </button>
                                        </span>
                                    </div>  
                                </div>
                                <div className='order_table_mobile_row'>
                                    <div onDoubleClick={((event) => {event.stopPropagation()})} className="order_table_mobile phone_delete">
                                        <span style={{ display: 'flex', gap: '5px', alignItems: 'center', color: '#000', justifyContent: 'center' }}>
                                            {item.phone} 
                                            <button className='button_order_table' onClick={handleClickCopy(String(item.phone))}>
                                                <i className='kt-nav__link-icon flaticon2-copy order_table'/>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        );
                    })
                }
            </tbody>
        )
    }

    const renderTable = () => {
        return(
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                <table className="kt-datatable__table" style={{ backgroundColor: '#00000000', overflow: 'visible' }}>
                    {window.innerWidth > 575 ? renderBody() : renderBodyMobile()}
                </table>
                {renderPagination()}
            </div>
        )
    }

    return(
        <div>
            <div  className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog modal-lg modal-dialog-scrollable" style={{ height: '90%', alignItems: 'center', justifyContent: 'center' }}>
                    <div ref={ref} className="modal-content" style={ window.innerWidth > 1024 ? {} : { maxHeight: 'calc(100vh - 200px)' }}>
                        <div className="modal-header">
                            <div>
                                <h5 className="modal-title"> История заказов {phone} </h5>                            
                                <span>{historyOrders.count} заказов за последние 3 месяца</span>
                            </div>
                            <button onClick={onClose} type="button" className="close"></button>                            
                        </div>
                        <div className="modal-body" style={{ paddingLeft: 0, paddingRight: 0 }}>
                            {renderTable()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(HistoryOrdersModal);