import React, { useEffect, useRef } from 'react';
import ErrorContainer from '../../components/utils/ErrorContainer';
import { useSelector } from 'react-redux';
import { fromStore } from '../../selectors';
import { useDispatch } from 'react-redux';
import { fetchCategories, fetchGoods, setActiveModalAction, updateGoodsPrices } from '../../action';
import { CategoryList, GoodsList } from '../../containers';
import { utils, writeFileXLSX, read } from 'xlsx';
import { xmlBackenHost } from '../../constants/app';
import { getNewPrice } from '../../utils/common';
import { setUIvisible } from '../../action/UImobile';
import HeadMobileActionMenu from '../../components/utils/HeadMobileActionMenu';

function ListGoodPage() {
    const refImport = useRef();
    const dispatch = useDispatch();
    const count = useSelector(fromStore.countGoodsSelector);
    const selectedCategoryGoods = useSelector(fromStore.selectedCategoryGoods);
    const goodListSelector = useSelector(fromStore.goodListSelector);
    const activeCategory = useSelector(fromStore.activeCategorySelector);

    const actionsMobile =[
        { name: 'Добавить товар', icon: 'flaticon2-plus', action: () => onOpenModalGood() },
        { name: 'Добавить категорию', icon: 'flaticon2-plus', action: () => onOpenModalCategory() },
        { name: 'Редактировать категорию', icon: 'la la-edit', action: () => onOpenModalCategory(activeCategory) },
        { name: 'Экспорт категории', icon: 'flaticon2-download', action: () => onExport(selectedCategoryGoods) },
        { name: 'Экспорт все', icon: 'flaticon2-download', action: () => onExport(goodListSelector) },
    ]

    const onRefresh = () => {
        dispatch(fetchGoods());
        dispatch(fetchCategories());
    };

    useEffect(() => {
        dispatch(setUIvisible(false, true))
    }, [dispatch])

    const onOpenModalCategory = (id) => {
        const categoryId = id || 'new';
        dispatch(setActiveModalAction({ field: 'category', value: categoryId }));
    };

    const onOpenModalGood = (id) =>  {
        const goodId = id || 'new';
        dispatch(setActiveModalAction({ field: 'good', value: goodId }));
    }

    const onExport = (items) => {
        const updateGoogs = items.reduce((prev = [], item) => {
            if(item.modifications.length > 0) {
                item.modifications.forEach(modification => {
                    const result = {
                        ...item, 
                        modifications: [{...modification}],
                        oldPrice: getNewPrice(item.oldPrice, modification.condition, modification.oldPrice, modification.persent, modification.active),
                        price: getNewPrice(item.price, modification.condition, modification.price, modification.persent, modification.active),
                        modificationId: modification.id,
                        modificationTitle: modification.title,
                    }
                    prev.push(result)
                });
            } else {
                prev.push(item)
            }

            return prev
        }, [])

        const filterGoods = updateGoogs.map(item => {
            return {
                'id': item.id,
                'Название': item.name,
                'id модификации': item.modificationId == null ? '-' : item.modificationId,
                'Модификация': item.modificationTitle == null ? '-' : item.modificationTitle,
                'Описание': item.description,
                'Цена': item.price,
                'Старая цена': item.oldPrice,
                'Ссылка на изображение товара': `${xmlBackenHost}${item.image== null ? '-' : item.image.url}`,
                'Id категории': item.category == null ? '-' : item.category.id,
                'Категория': item.category == null ? '-' : item.category.title,
            }
        })
        const ws = utils.json_to_sheet(filterGoods);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        writeFileXLSX(wb, "Товары2.0.xlsx");
    }

    const onImport = async (e) => {
        if (!e.target.files[0]) return;

        const file = e.target.files[0];

        if (!file.arrayBuffer) return;

        const data = await file.arrayBuffer();

        if (!data) return;

        if(data) {
            const wb = read(data);
            const ws = wb.Sheets[wb.SheetNames[0]];
            const pricesExcel = utils.sheet_to_json(ws);
            const result = pricesExcel.map(item => {
                const price = {
                    id: item.id,
                    modifications: item['Модификация'],
                    modificationsId: item['id модификации'],
                    price: item['Цена'],
                    oldPrice: item['Старая цена'],
                }
                return price;
            });
            const res = await dispatch(updateGoodsPrices({ updateData: result }));

            if (res) onRefresh();
        }
        refImport.current.value = "";
    }

    const renderSubHeader = () => {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Товары</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc" id="kt_subheader_total">
                            {count}
                        </span>
                    </div>
                </div>
                <div className="kt-subheader__toolbar">
                    {window.innerWidth > 1024 && 
                        <>
                            <button onClick={(() => onExport(selectedCategoryGoods))} className="btn btn-dark btn-icon-sm">Экспорт категории</button>
                            <button onClick={(() => onExport(goodListSelector))} className="btn btn-dark btn-icon-sm">Экспорт все</button>
                        </>
                    }
                    <label className="btn btn-dark btn-icon-sm">
                        <input type="file" onChange={onImport} ref={refImport} hidden/>
                        Импорт
                    </label>
                    {window.innerWidth > 1024 && <button 
                    onClick={((e) => {
                        e.stopPropagation();
                        onOpenModalGood();
                    })}
                    className="btn btn-success btn-icon-sm"
                    >
                        Добавить товар
                    </button>}
                    <button onClick={onRefresh} className="btn btn-secondary btn-icon"><i className="la la-refresh"></i></button>
                </div>
            </div>
            </div>
        );
    };

    return (
        <div>
            <HeadMobileActionMenu actions={actionsMobile}/>
            { renderSubHeader() }
            <div className='kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                <ErrorContainer field="goods" hasCloseButton={true}/>
                <ErrorContainer field="category" hasCloseButton={true}/>
                <div className="kt-grid kt-grid--desktop kt-grid--ver-desktop kt-todo goods">
                    <div className='kt-grid__item kt-portlet kt-todo__aside goods'>
                       <CategoryList/>
                    </div>
                    <div className="kt-grid__item kt-grid__item--fluid kt-todo__content">
                        <GoodsList/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListGoodPage;