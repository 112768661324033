import React, { Component } from "react";
import { connect } from "react-redux";
import { addDeliveryPriced, addPositionNewOrder, changeCountAdditives, changeCountPosition, changeModificationOrderPositionList, changeSale, changeUnit, clearNewOrder, deleteAdditivesNewOrder, deletePositionNewOrder } from "../../action/neworder";
import AdditivesWOKNewOrder from "./AdditivesWOKNewOrder";
import SaveNewOrderButton from "./SaveNewOrderButton";
import RobovoiceCallDownloadButton from './RobovoiceCallDownloadButton';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { roleId } from "../../constants/app";

class OrderPositionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputSale: '',
            openInputSale: false,
            openAdditivesWOK: false,
            openSaleDropDown: false,
            openUnitDropDown: false,
            openDescription: false,
            openDeliveryPriced: false
        };
        this.closeAdditivesWOK = this.closeAdditivesWOK.bind(this);
      }

    closeAdditivesWOK(){
        this.setState({openAdditivesWOK: false})
    }

    checkChangeCountPositionMinus(addCount) {
        if(this.props.user.role.id !== roleId.cashier || !this.props.editOrder || (addCount || 0) > 0)
            return true;
        return false
    };

    handleChangeInputSale(e){
        if(e.target.value <= 999999 && e.target.value > 0)
            return this.setState({inputSale: parseInt(e.target.value)})

        if(e.target.value > 999999)
            return this.setState({inputSale: 999999})

        if(e.target.value <= 0)
            return this.setState({inputSale: ''})
    };

    renderGoodPrice(goodId, price, oldPrice) {
        if(!this.props.neworders.isAggPrice) {
            if(price) {
                return price;
            } else {
                return this.props.goodsChek.find((el) => el.id === parseInt(goodId)).price ? this.props.goodsChek.find((el) => el.id === parseInt(goodId)).price : 0;
            }
        } else {
            if(oldPrice) {
                return oldPrice;
            } else {
                return this.props.goodsChek.find((el) => el.id === parseInt(goodId)).oldPrice ? this.props.goodsChek.find((el) => el.id === parseInt(goodId)).oldPrice : 0;
            }
        }
    }

    render() {
        return(
            <>
             <div className="position_list_row head">
                    <span className="position_list_position head_name"> Наименование </span>
                    <span className="position_list_position count" style={{ justifyContent: 'center' }}> Кол-во </span>
                    <span className="position_list_position price"> Цена </span>
                    <span className="position_list_position totalPrice"> Итого </span>
                </div>
                <div className="order_position_list_body">
                    {
                        this.props.neworders.goods && this.props.neworders.goods.map((item) => 
                            <>
                                <div key={item.index} className="position_list_column">
                                    <div className="position_list_row">
                                        <div className="position_list_position container_name"> 
                                            <div className="position_list_position row_name">
                                                {((item.category && item.category.id) ? item.category.id === this.props.settings.mainWok : item.wokData) &&
                                                <button
                                                className="order_position_list_inf_button"
                                                style={{ width: '1.5rem', height: '1.5rem', fontSize: '1.5rem'}}
                                                onClick={(() => this.setState({openAdditivesWOK: item.index}))}
                                                onBlur={this.closeAdditivesWOK}
                                                >
                                                    +
                                                </button>
                                                }
                                                <span className="position_list_position name">{`${!item.wokData ? ((item.data && item.data.title) ? item.data.title : item.name ? item.name : 'NaN') : (item.wokData.main ? item.wokData.main.title : 'No Title')} ${(item.category === this.props.settings.pizza || (item.categoryName && item.categoryName.toLowerCase() === 'пицца')) ? item.isMod ? '36см' : '30см' : ''}`}</span>
                                                {!item.wokData && item.description && item.description !== '-' && 
                                                <button 
                                                className="order_position_list_inf_button"
                                                onClick={(() => this.setState({openDescription : item.index}))}
                                                onBlur={(() => this.setState({openDescription : false}))}
                                                >
                                                    i
                                                </button>
                                                }                                        
                                            </div>                                        
                                            {(this.checkChangeCountPositionMinus() || item.count === item.addCount) && (item.category === this.props.settings.pizza || (item.categoryName && item.categoryName.toLowerCase() === 'пицца')) &&  
                                            <div className="position_list_position row_name" style={{marginTop: '5px'}}>                                    
                                                <div className="neworder_pizza_modification_button_div">
                                                    {item.modifications 
                                                    ?
                                                    <>
                                                        <button 
                                                            className={`neworder_pizza_modification_button ${!item.isMod ? 'actived' : ''}`} 
                                                            onClick={(() => this.props.changeModificationOrderPositionList(item.index, item.modifications.find((mod) => mod.default, this.props.goods.find((el) => el.id === item.id || el.id === parseInt(String(item.id).split('_')[0]))), item))}> 
                                                            30см
                                                        </button>
                                                        <button 
                                                            className={`neworder_pizza_modification_button ${item.isMod ? 'actived' : ''}`} 
                                                            onClick={(() => this.props.changeModificationOrderPositionList(item.index, item.modifications.find((mod) => !mod.default, this.props.goods.find((el) => el.id === item.id || el.id === parseInt(String(item.id).split('_')[0]))), item))}> 
                                                            36см
                                                        </button>
                                                    </>
                                                    : 
                                                        this.props.goods.find((el) => el.id === item.id || el.id === parseInt(String(item.id).split('_')[0])).modifications &&
                                                        <>
                                                            <button 
                                                                className={`neworder_pizza_modification_button ${!item.isMod ? 'actived' : ''}`} 
                                                                onClick={(() => this.props.changeModificationOrderPositionList(item.index, this.props.goods.find((el) => el.id === item.id || el.id === parseInt(String(item.id).split('_')[0])).modifications.find((mod) => mod.default), this.props.goods.find((el) => el.id === item.id || el.id === parseInt(String(item.id).split('_')[0]))))}> 
                                                                30см
                                                            </button>
                                                            <button 
                                                                className={`neworder_pizza_modification_button ${item.isMod ? 'actived' : ''}`} 
                                                                onClick={(() => this.props.changeModificationOrderPositionList(item.index, this.props.goods.find((el) => el.id === item.id || el.id === parseInt(String(item.id).split('_')[0])).modifications.find((mod) => !mod.default), this.props.goods.find((el) => el.id === item.id || el.id === parseInt(String(item.id).split('_')[0]))))}> 
                                                                36см
                                                            </button>
                                                        </>
                                                    }
                                                </div>                                        
                                            </div>
                                            }                                    
                                        </div>
                                        <span className="position_list_position count"> 
                                            {(this.checkChangeCountPositionMinus(item.addCount)) && <button 
                                            className="position_list_count_button"
                                            onClick={(() => {
                                                if(!item.wokData)
                                                    return this.props.changeCountPosition(item.index, item.count, 'minus')
                                                return this.props.changeCountAdditives(item.index, item.count, 'minus',  item.wokData.main.id, 'main')
                                            })}
                                            >
                                                -
                                            </button>}
                                            <input
                                            className="new_order_input count"
                                            type="number"
                                            value={item.count}
                                            onChange={((e) => {
                                                if(this.checkChangeCountPositionMinus()) {
                                                    if(!item.wokData)
                                                        return this.props.changeCountPosition(item.index, item.count, 'handle', e.target.value)
                                                    return this.props.changeCountAdditives(item.index, item.count , 'handle',  item.wokData.main.id, 'main', e.target.value)
                                                } else {
                                                    const minValue = item.count - (item.addCount || 0);
                                                    let value = e.target.value;
                                                        if(e.target.value < minValue) 
                                                            value = minValue;
                                                    if(!item.wokData)
                                                        return this.props.changeCountPosition(item.index, item.count, 'handle', value)
                                                    return this.props.changeCountAdditives(item.index, item.count , 'handle',  item.wokData.main.id, 'main', value)
                                                        
                                                }
                                            })}
                                            onBlur={((e) => {
                                                if(this.checkChangeCountPositionMinus()) {
                                                    if(parseInt(e.target.value) <= 0 || !e.target.value)
                                                        return this.props.deletePositionNewOrder(item.index);
                                                } else {
                                                    const minValue = (item.count || 0) - (item.addCount || 0);
                                                    if(parseInt(e.target.value) <= 0 || !e.target.value) {
                                                        if(minValue <= 0) {
                                                            return this.props.deletePositionNewOrder(item.index);
                                                        } else {
                                                            if(!item.wokData)
                                                                return this.props.changeCountPosition(item.index, item.count, 'handle', minValue)
                                                            return this.props.changeCountAdditives(item.index, item.count , 'handle',  item.wokData.main.id, 'main', minValue)
                                                        }
                                                    }
                                                }
                                            })}
                                            />
                                            <button 
                                            className="position_list_count_button"
                                            onClick={(() => {
                                                if(!item.wokData)
                                                    return this.props.changeCountPosition(item.index, item.count, 'plus')
                                                return this.props.changeCountAdditives(item.index, item.count , 'plus',  item.wokData.main.id, 'main')
                                            })}
                                            >
                                                +
                                            </button> 
                                        </span>                                    
                                        {
                                        !item.wokData
                                        ?                                            
                                            <>
                                                <span className="position_list_position price">  {`${this.renderGoodPrice(item.id, item.price, item.oldPrice)} ₽`} </span>
                                                <span className="position_list_position totalPrice"> {`${item.count ? this.renderGoodPrice(item.id, item.price, item.oldPrice)  * item.count : ''} ₽`} </span>
                                            </>
                                        :
                                            <>
                                                <span className="position_list_position price">  {`${this.renderGoodPrice(item.wokData.main.id, item.wokData.main.price, item.wokData.main.oldPrice)} ₽`} </span>
                                                <span className="position_list_position totalPrice"> {`${item.count  ? this.renderGoodPrice(item.wokData.main.id, item.wokData.main.price, item.wokData.main.oldPrice) * item.count  : ''} ₽`} </span>
                                            </>
                                        }
                                    </div>                                
                                    {this.state.openAdditivesWOK === item.index &&
                                    <div className="position_list_position row_name">
                                        <AdditivesWOKNewOrder index={item.index} closeAdditivesWOK={this.closeAdditivesWOK} editOrder={this.props.editOrder} user={this.props.user} count={item.count} addCount={item.addCount || 0}/>
                                    </div>
                                    }
                                    {item.wokData && item.wokData.meat && Object.values(item.wokData.meat).map((el) => 
                                    <div key={item.index + '_' + el.data.id} className="position_list_row">
                                        <div className="position_list_position container_name"> 
                                            <div className="position_list_position row_name">
                                                <span className="position_list_position name">{'-- ' + el.data.title}</span>                                   
                                            </div>                                
                                        </div>
                                        <span className="position_list_position count"> 
                                            {(this.checkChangeCountPositionMinus(el.addCount)) && <button 
                                            className="position_list_count_button"
                                            onClick={(() => this.props.changeCountAdditives(item.index, el.count, 'minus',  el.data.id, 'meat'))}
                                            >
                                                -
                                            </button> }
                                            <input
                                            className="new_order_input count"
                                            type="number"
                                            value={el.count}
                                            onChange={((e) => {
                                                if(this.checkChangeCountPositionMinus()) {
                                                    return this.props.changeCountAdditives(item.index, el.count, 'handle',  el.data.id, 'meat', e.target.value);
                                                } else {
                                                    const minValue = item.count - (item.addCount || 0);
                                                    let value = e.target.value;
                                                        if(e.target.value < minValue) 
                                                            value = minValue;
                                                    return this.props.changeCountAdditives(item.index, el.count, 'handle',  el.data.id, 'meat', value);
                                                }
                                            })}
                                            onBlur={((e) => {
                                                if(this.checkChangeCountPositionMinus()) {
                                                    if(parseInt(e.target.value) <= 0 || !e.target.value)
                                                        return this.props.deleteAdditivesNewOrder(item.index, el.data.id, 'meat');
                                                } else {
                                                    const minValue = (item.count || 0) - (item.addCount || 0);
                                                    if(parseInt(e.target.value) <= 0 || !e.target.value) {
                                                        if(minValue <= 0) {
                                                           return this.props.deleteAdditivesNewOrder(item.index, el.data.id, 'meat');
                                                        } else {
                                                            return this.props.changeCountAdditives(item.index, el.count, 'handle',  el.data.id, 'meat', minValue);
                                                        }
                                                    }
                                                }
                                            })}
                                            />
                                            <button 
                                            className="position_list_count_button"
                                            onClick={(() => this.props.changeCountAdditives(item.index, el.count, 'plus',  el.data.id, 'meat'))}
                                            >
                                                +
                                            </button> 
                                        </span>
                                        <span className="position_list_position price">  {`${this.renderGoodPrice(el.data.id, el.data.price, el.data.oldPrice)} ₽`} </span>
                                        <span className="position_list_position totalPrice"> {`${el.count ? this.renderGoodPrice(el.data.id, el.data.price, el.data.oldPrice) * el.count : ''} ₽`} </span>
                                    </div>
                                    )}     
                                    {item.wokData && item.wokData.topping && Object.values(item.wokData.topping).map((el) => 
                                    <div key={item.index + '_' + el.data.id} className="position_list_row">
                                        <div className="position_list_position container_name"> 
                                            <div className="position_list_position row_name">
                                                <span className="position_list_position name">{'-- ' + el.data.title}</span>                                   
                                            </div>                                
                                        </div>
                                        <span className="position_list_position count"> 
                                            {(this.checkChangeCountPositionMinus(el.addCount)) && <button 
                                            className="position_list_count_button"
                                            onClick={(() => this.props.changeCountAdditives(item.index, el.count, 'minus',  el.data.id, 'topping'))}
                                            >
                                                -
                                            </button>}
                                            <input
                                            className="new_order_input count"
                                            type="number"
                                            value={el.count}                                            
                                            onChange={((e) => {
                                                if(this.checkChangeCountPositionMinus()) {
                                                    return this.props.changeCountAdditives(item.index, el.count, 'handle',  el.data.id, 'topping', e.target.value);
                                                } else {
                                                    const minValue = item.count - (item.addCount || 0);
                                                    let value = e.target.value;
                                                        if(e.target.value < minValue) 
                                                            value = minValue;
                                                    return this.props.changeCountAdditives(item.index, el.count, 'handle',  el.data.id, 'topping', value);
                                                }
                                            })}
                                            onBlur={((e) => {
                                                if(this.checkChangeCountPositionMinus()) {
                                                    if(parseInt(e.target.value) <= 0 || !e.target.value)
                                                        return this.props.deleteAdditivesNewOrder(item.index, el.data.id, 'topping');
                                                } else {
                                                    const minValue = (item.count || 0) - (item.addCount || 0);
                                                    if(parseInt(e.target.value) <= 0 || !e.target.value) {
                                                        if(minValue <= 0) {
                                                           return this.props.deleteAdditivesNewOrder(item.index, el.data.id, 'topping');
                                                        } else {
                                                            return this.props.changeCountAdditives(item.index, el.count, 'handle',  el.data.id, 'topping', minValue);
                                                        }
                                                    }
                                                }
                                            })}
                                            />
                                            <button 
                                            className="position_list_count_button"
                                            onClick={(() => this.props.changeCountAdditives(item.index, el.count, 'plus',  el.data.id, 'topping'))}
                                            >
                                                +
                                            </button> 
                                        </span>
                                        <span className="position_list_position price">  {`${this.renderGoodPrice(el.data.id, el.data.price, el.data.oldPrice)} ₽`} </span>
                                        <span className="position_list_position totalPrice"> {`${el.count ? this.renderGoodPrice(el.data.id, el.data.price, el.data.oldPrice) * el.count : ''} ₽`} </span>
                                    </div>
                                    )}         
                                    {item.wokData && item.wokData.souce && Object.values(item.wokData.souce).map((el) => 
                                    <div key={item.index + '_' + el.id} className="position_list_row">
                                        <div className="position_list_position container_name"> 
                                            <div className="position_list_position row_name">
                                                <span className="position_list_position name">{'-- ' + el.title}</span>                                   
                                            </div>                                
                                        </div>
                                        <span className="position_list_position count"> 
                                            {(this.checkChangeCountPositionMinus(el.addCount)) && <button 
                                            className="position_list_count_button"
                                            onClick={(() => this.props.changeCountAdditives(item.index, (el.count || 1), 'minus',  el.id, 'souce'))}
                                            >
                                                -
                                            </button>} 
                                            <input
                                            className="new_order_input count"
                                            type="number"
                                            value={el.count}                                            
                                            onChange={((e) => {
                                                if(this.checkChangeCountPositionMinus()) {
                                                    return this.props.changeCountAdditives(item.index, el.count, 'handle',  el.id, 'souce', e.target.value);
                                                } else {
                                                    const minValue = item.count - (item.addCount || 0);
                                                    let value = e.target.value;
                                                        if(e.target.value < minValue) 
                                                            value = minValue;
                                                    return this.props.changeCountAdditives(item.index, el.count, 'handle',  el.id, 'souce', value);
                                                }
                                            })}
                                            onBlur={((e) => {
                                                if(this.checkChangeCountPositionMinus()) {
                                                    if(parseInt(e.target.value) <= 0 || !e.target.value)
                                                        return this.props.deleteAdditivesNewOrder(item.index, el.id, 'souce');
                                                } else {
                                                    const minValue = (item.count || 0) - (item.addCount || 0);
                                                    if(parseInt(e.target.value) <= 0 || !e.target.value) {
                                                        if(minValue <= 0) {
                                                           return this.props.deleteAdditivesNewOrder(item.index, el.id, 'souce');
                                                        } else {
                                                            return this.props.changeCountAdditives(item.index, el.count, 'handle',  el.id, 'souce', minValue);
                                                        }
                                                    }
                                                }
                                            })}
                                            />
                                            <button 
                                            className="position_list_count_button"
                                            onClick={(() => this.props.changeCountAdditives(item.index, (el.count || 0), 'plus',  el.id, 'souce'))}
                                            >
                                                +
                                            </button> 
                                        </span>
                                        <span className="position_list_position price">  {`${this.renderGoodPrice(el.id, el.price, el.oldPrice)} ₽`} </span>
                                        <span className="position_list_position totalPrice"> {`${el.count ? this.renderGoodPrice(el.id, el.price, el.oldPrice) * el.count : '0'} ₽`} </span>
                                    </div>
                                    )}   
                                </div>
                                {this.state.openDescription === item.index &&
                                <div className="position_list_row" style={{paddingLeft: '0px'}}>   
                                    <div className="order_position_footer_dropdown_container description">
                                        <div className="order_position_footer_dropdown description" onMouseDown={((e) => e.preventDefault())}>
                                            {"Состав: " + item.description.split('Энергетическая')[0]}
                                        </div>
                                    </div>
                                </div>
                                }     
                            </>
                        )
                    }

                    {parseInt(this.props.neworders.unit) > 0 && parseInt(this.props.neworders.goodPrice) !== 0 && 
                    <div className="position_list_column">
                        <div className="position_list_row">
                            <div className="position_list_position container_name"> 
                                <div className="position_list_position row_name">
                                    <span className="position_list_position name">Приборы</span>                                   
                                </div>                                
                            </div>
                            <span className="position_list_position count"> {this.props.neworders.unit} </span>
                            <span className="position_list_position price"> 0 ₽ </span>
                            <span className="position_list_position totalPrice"> 0 ₽ </span>     
                        </div>          
                    </div>      
                    }

                    {this.props.neworders.deliveryPrice > 0 && parseInt(this.props.neworders.goodPrice) !== 0 && 
                    <div className="position_list_column">
                        <div className="position_list_row">
                            <div className="position_list_position container_name"> 
                                <div className="position_list_position row_name">
                                    <span className="position_list_position name">Доставка</span>                                   
                                </div>                                
                            </div>
                            <span className="position_list_position count"></span>
                            <span className="position_list_position price"> {`${this.props.neworders.deliveryPrice} ₽`} </span>
                            <span className="position_list_position totalPrice"> {`${this.props.neworders.deliveryPrice} ₽`} </span>     
                        </div>       
                    </div>         
                    }

                    {this.props.neworders.promocode &&
                        <div className="position_list_row totalPrice">
                            <span>Промокод</span>
                            <Link to={/promocodes/}>{this.props.neworders.promocode.name}</Link>     
                        </div>    
                    }    

                    {this.props.neworders.bonusCount > 0 &&
                    <div className="position_list_row totalPrice">
                        <span>Оплачено бонусами</span>
                        <span>{this.props.neworders.bonusCount + ' ₽'}</span>  
                    </div>
                    }
                    
                    {parseInt(this.props.neworders.goodPrice) !== 0 && 
                        <div className="position_list_row totalPrice">
                            <span>Всего</span>
                            <span>{this.props.neworders.goodPrice + ' ₽'}</span>       
                        </div>                
                    } 
                      
                </div>      
                <div className="order_position_list_footer">
                    {(parseInt(this.props.neworders.totalPrice) !== 0 || this.props.neworders.salePrice > 0) && 
                        <div className="position_list_row totalPrice" style={{paddingTop: '0px'}}>
                            <span>{this.props.neworders.salePrice > 0 && `Скидка ${this.props.neworders.sale ? this.props.neworders.sale.name  ? this.props.neworders.sale.name + ' ' : '' : ''}` + this.props.neworders.salePrice + ' ₽'} </span>
                            <span>{'К оплате '} <span style={{fontWeight: '600'}}>{this.props.neworders.totalPrice}</span> {' ₽'}</span>       
                        </div>                
                    }      
                    {(parseInt(this.props.neworders.totalPrice) !== 0 || this.props.neworders.salePrice > 0) &&
                        <div className="order_position_list_footer_buttons_div">
                            <div className="order_position_list_footer_acide_buttons">
                                {!this.state.openInputSale ?
                                <>
                                    <div>                                        
                                        {this.state.openSaleDropDown && 
                                        <div className="order_position_footer_dropdown_container">
                                            <div className="order_position_footer_dropdown">
                                                    <button
                                                    className={`order_position_footer_dropdown_button ${!this.props.neworders.sale && parseFloat(this.props.neworders.salePrice) === 0 ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => {
                                                        this.props.changeSale('percent', null);
                                                        this.setState({openSaleDropDown : false});
                                                    })}
                                                    >
                                                        Без скидки
                                                    </button>       
                                                    <button 
                                                    className={`order_position_footer_dropdown_button ${this.props.neworders.sale === 0.05 ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => {
                                                        this.props.changeSale('percent', 0.05);
                                                        this.setState({openSaleDropDown : false});
                                                    })}
                                                    >
                                                        -5%
                                                    </button>                                    
                                                    <button 
                                                    className={`order_position_footer_dropdown_button ${this.props.neworders.sale === 0.1 ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => {
                                                        this.props.changeSale('percent', 0.1);
                                                        this.setState({openSaleDropDown : false});
                                                    })}
                                                    >
                                                        -10%
                                                    </button>                                    
                                                    <button 
                                                    className={`order_position_footer_dropdown_button ${this.props.neworders.sale === 0.15 ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => {
                                                        this.props.changeSale('percent', 0.15);
                                                        this.setState({openSaleDropDown : false});
                                                    })}
                                                    >
                                                        -15%
                                                    </button>     
                                                    <button 
                                                    className={`order_position_footer_dropdown_button ${this.props.neworders.sale === 0.2 ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => {
                                                        this.props.changeSale('percent', 0.2);
                                                        this.setState({openSaleDropDown : false});
                                                    })}
                                                    >
                                                        -20%
                                                    </button>                                    
                                                    <button 
                                                    className={`order_position_footer_dropdown_button ${this.props.neworders.sale === 0.25 ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => {
                                                        this.props.changeSale('percent', 0.25);
                                                        this.setState({openSaleDropDown : false});
                                                    })}
                                                    >
                                                        -25%
                                                    </button>                                    
                                                    <button 
                                                    className={`order_position_footer_dropdown_button ${this.props.neworders.sale === 0.3 ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => {
                                                        this.props.changeSale('percent', 0.3);
                                                        this.setState({openSaleDropDown : false});
                                                    })}
                                                    >
                                                        -30%
                                                    </button>        
                                                    {this.props.user.role.id !== roleId.cashier && <button 
                                                    className={`order_position_footer_dropdown_button ${this.props.neworders.sale === null && this.props.neworders.salePrice > 0 ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => this.setState({openInputSale: true, openSaleDropDown: false}))}
                                                    >
                                                        Суммой
                                                    </button>}                                         
                                            </div>
                                        </div>
                                        }
                                        <button 
                                            className={`order_position_list_footer_button ${(this.props.neworders.sale || this.props.neworders.salePrice > 0) ? 'actived' : ''}`}
                                            onClick={(() => this.setState({openSaleDropDown : !this.state.openSaleDropDown}))}
                                            onBlur={(() => this.setState({openSaleDropDown : false}))}
                                            >
                                                {this.props.neworders.sale && (this.props.neworders.sale * 100)}%
                                            </button>
                                        </div>
                                    <div>
                                        {this.state.openUnitDropDown &&
                                        <div className="order_position_footer_dropdown_container">
                                            <div className="order_position_footer_dropdown">                                
                                            {
                                                Array.from({length: 21}).map((_, idx) => 
                                                    <button 
                                                    key={idx}
                                                    className={`order_position_footer_dropdown_button ${(this.props.neworders.unit === idx || (this.props.neworders.unit === null && idx === 0 )) ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => {
                                                        this.props.changeUnit(idx);
                                                        this.setState({openUnitDropDown : false});
                                                    })}
                                                    >
                                                        {idx > 0 ? idx : 'Без приборов'}    
                                                    </button>
                                                )
                                            }
                                            </div>
                                        </div>
                                        }
                                        <button 
                                        className="order_position_list_footer_button"
                                        onClick={(() => this.setState({openUnitDropDown: !this.state.openUnitDropDown}))}
                                        onBlur={(() => this.setState({openUnitDropDown: false}))}
                                        >
                                            <i className="flaticon-more-v2"/>
                                        </button>                                                                                
                                    </div>                                    
                                </>
                                :
                                <>
                                    <div style={{ display: 'flex', gap: '5px', width: '50%'}}>
                                        <input 
                                        className="new_order_input"
                                        style={{ width: '100%'}}
                                        type="number"
                                        placeholder="Скидка"
                                        value={this.state.inputSale}
                                        onChange={this.handleChangeInputSale.bind(this)}
                                        ref={(input) => {
                                            if(input)                            
                                              input.focus();                                            
                                        }}
                                        onBlur={(() => {
                                                this.setState({inputSale: '', openInputSale: false})
                                        })}
                                        />
                                        <button 
                                        className="order_position_list_footer_button"
                                        style={{ padding: '0px', height: '1.6rem', minWidth: '2rem'}}
                                        onMouseDown={((e) => e.preventDefault())}
                                        onClick={(() => {
                                            if(this.state.inputSale)       
                                                this.props.changeSale('handle', this.state.inputSale);    
                                            this.setState({inputSale: '', openInputSale: false});
                                        })}
                                        >
                                            <i className="flaticon2-check-mark"/>
                                        </button>
                                    </div>
                                </>
                                }
                                {this.props.neworders.zones && this.props.neworders.deliveryMethod === 'delivery' &&                           
                                    <div>
                                        {this.state.openDeliveryPriced &&
                                        <div className="order_position_footer_dropdown_container">
                                            <div className="order_position_footer_dropdown">           
                                            <button 
                                            className={`order_position_footer_dropdown_button ${this.props.neworders.deliveryPrice === 0 ? 'actived' : ''}`}
                                            onMouseDown={((e) => e.preventDefault())}
                                            onClick={(() => this.props.addDeliveryPriced(0, null))}
                                            >
                                                Бесплатная доставка    
                                            </button>                     
                                            {
                                                this.props.neworders.zones.map((item) => 
                                                <button 
                                                key={item.deliveryCode}
                                                className={`order_position_footer_dropdown_button ${this.props.neworders.deliveryPrice === item.deliveryPrice ? 'actived' : ''}`}
                                                onMouseDown={((e) => e.preventDefault())}
                                                onClick={(() => this.props.addDeliveryPriced(item.deliveryPrice, item.deliveryCode))}
                                                >
                                                    {item.deliveryPrice + ' ₽'}    
                                                </button>
                                                )
                                            }
                                            </div>
                                        </div>
                                        }
                                        <button
                                        className={`order_position_list_footer_button ${(this.props.neworders.deliveryPrice > 0) ? 'actived' : ''}`}
                                        style={{ padding: '0.15rem 0.75rem' }}
                                        onClick={(() => this.setState({openDeliveryPriced: true}))}
                                        onBlur={(() => this.setState({openDeliveryPriced: false}))}
                                        >
                                            Доставка
                                        </button>
                                    </div>
                                }
                            </div>
                            <div className="order_position_list_footer_acide_buttons">
                                <RobovoiceCallDownloadButton />
                                {(this.checkChangeCountPositionMinus()) &&<button 
                                className="order_position_list_footer_button"
                                onClick={(() => {this.props.clearNewOrder()})}
                                >
                                    <i className="flaticon2-trash"/>
                                </button>}
                                <SaveNewOrderButton editOrder={this.props.editOrder} editedOrder={this.props.editedOrder}/>
                            </div>
                        </div>
                    }
                </div>   
            </>            
        );
    }
}

const mapStateToProps = state => ({
    neworders: state.neworders,
    settings: state.settings.init.categories,
    goodsChek: state.good.list,
    user: state.user.info
});

const mapDispatchToProps = {
    changeCountPosition: (index, count, type, value) => changeCountPosition(index, count, type, value),
    deletePositionNewOrder: (id) => deletePositionNewOrder(id),
    changeUnit: (value) => changeUnit(value),
    changeSale: (type, value) => changeSale(type, value),
    clearNewOrder: () => clearNewOrder(),
    addPositionNewOrder: (good, settings) => addPositionNewOrder(good, settings),
    changeModificationOrderPositionList: (index, mod, good) => changeModificationOrderPositionList(index, mod, good),
    changeCountAdditives: (index, count, type, idAdditives, subtype, value) => changeCountAdditives(index, count, type, idAdditives, subtype, value),
    deleteAdditivesNewOrder: (index, id, subtype) => deleteAdditivesNewOrder(index, id, subtype),
    addDeliveryPriced: (price, article) => addDeliveryPriced(price, article)
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderPositionList);