import React, { Component } from 'react';
import { Link, withRouter, Redirect } from "react-router-dom";
import classnames from 'classnames';
import { pages } from '../../constants/page';
import SidebarIcon from '../layout/SidebarIcon';
import { connect } from 'react-redux';
import { deleteCookie } from '../../utils/cookie';
import { setUIdefault, setUIOpenSidebar } from '../../action/UImobile';

function Header({ UImobileOpenSidebar }) {
    return (
        <div className="kt-aside__brand kt-grid__item" id="kt_aside_brand" style={{ paddingLeft: '18px', overflow: 'hidden' }}>
            <div className="kt-aside__brand-logo" style={!UImobileOpenSidebar ? {width: '0px', overflow: 'hidden', transition: 'all 0.3s ease'} : { transition: 'all 0.3s ease' }}>
                <img alt="Logo" src="/images/logo2.png" style={{ width: '230px' }}/>
            </div>
                    <div className="kt-aside__brand-tools">
                        <button
                            className="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left"
                            id="kt_aside_toggler">
                            <span></span>
                        </button>
                    </div>
                </div>
                
    );
}

function Icon() {
    return (
        <span className="kt-menu__link-icon" style={ {flex: '0 0 40px'}}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                className="kt-svg-icon">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon id="Bound" points="0 0 24 0 24 24 0 24"/>
                    <path
                        d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
                        id="Shape"
                        fill="#000000"
                        fillRule="nonzero"/>
                    <path
                        d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
                        id="Path"
                        fill="#000000"
                        opacity="0.3"/>
                </g>
            </svg>
        </span>
    );
}

class Sidebar extends Component {
    state = {
        activeSub: null,
        exit: false
    }

    singOut = (e) => {
        e.preventDefault();
        this.props.setUIdefault();
        deleteCookie('auth');
        this.setState({ exit: true });
      }

    onActiveSub = (page) => (e) => {
        this.setState({ activeSub: page === this.state.activeSub ? null : page });
    }

    handleOpenSidebar = (value) => {
        this.props.setUIOpenSidebar(value);
        if(!value)
            this.setState({ activeSub: null});
    }

    render() {
        if (this.state.exit) return <Redirect to="/login"/>;
        return (
            <div onMouseOver={() => this.handleOpenSidebar(true)} onMouseLeave={() => this.handleOpenSidebar(false)} className={`kt-aside${this.props.UImobile.openSidebar ? ' active' : ''} kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop sidebar`} id="kt_aside">
                {window.innerWidth > 1024 && <Header UImobileOpenSidebar = {this.props.UImobile.openSidebar} />}
                <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
                    <div
                        id="kt_aside_menu"
                        className="kt-aside-menu"
                        style={{margin: '0px'}}
                        data-ktmenu-vertical="1"
                        data-ktmenu-scroll="1"
                        data-ktmenu-dropdown-timeout="500">

                        <ul className="kt-menu__nav scrolled">                            

                            <li title='Панель управления' className={classnames({ 'kt-menu__item': true, 'kt-menu__item--active': this.props.match.path === '/' })} style={{ paddingTop: '30px' }} aria-haspopup="true">
                                <Link to="/" 
                                onClick={(() => {
                                    this.props.setUIdefault();
                                })} 
                                className="kt-menu__link" 
                                style={{ paddingLeft: '21px', transition: 'all 0.3s ease' }}
                                >
                                    <Icon/>
                                    <span className="kt-menu__link-text" style={{ minWidth: '130px'  } }>Панель управления</span>
                                </Link>
                            </li>

                            <li className="kt-menu__section">
                                <h4 className="kt-menu__section-text">{this.props.UImobile.openSidebar ? 'Приложения' : ''}</h4>
                                <i className="kt-menu__section-icon flaticon-more-v2"></i>
                            </li>

                            {
                                pages.filter(page => page.path !== '/' && !page.disableSidebar).filter(page => page.access === undefined || page.access.includes(this.props.user.role.id)).map(page => (
                                    <li
                                        title={page.sub ? null : page.title}
                                        key={page.name}
                                        className={classnames({
                                            'kt-menu__item': true,
                                            'kt-menu__item--submenu': page.sub,
                                            'kt-menu__item--active': this.props.match.path === page.path,
                                            'kt-menu__item--open': this.state.activeSub === page.name
                                        })}>
                                        {
                                            page.sub ? (
                                                <div title={page.title} onClick={this.onActiveSub(page.name)} className="kt-menu__link" style={{paddingLeft: '21px'}}>
                                                    <SidebarIcon name={page.name}/>
                                                    <span className="kt-menu__link-text" style={{ minWidth: '110px'} }>{page.title}</span>
                                                    <i className="kt-menu__ver-arrow la la-angle-right"></i>
                                                </div>
                                            ) : (
                                                <Link
                                                title={page.title}
                                                to={page.path} 
                                                onClick={(() => {
                                                    this.props.setUIdefault();
                                                })} 
                                                className="kt-menu__link" 
                                                style={{paddingLeft: '21px'}}
                                                >
                                                    <SidebarIcon name={page.name}/>
                                                    <span className="kt-menu__link-text" style={{ minWidth: '110px'} }>{page.title}</span>
                                                    {
                                                        page.sub && (
                                                            <i className="kt-menu__ver-arrow la la-angle-right"></i>
                                                        )
                                                    }
                                                </Link>
                                            )
                                        }
                                        

                                        {
                                            page.sub && (
                                                <div className="kt-menu__submenu ">
                                                    <span className="kt-menu__arrow"></span>
                                                    
                                                    <ul className="kt-menu__subnav">
                                                        {
                                                            page.sub.filter(item => !item.disableSidebar && (item.access === undefined || item.access.includes(this.props.user.role.id))).map(item => (
                                                                <li key={item.name} className={
                                                                    classnames({
                                                                        'kt-menu__item': true,
                                                                        
                                                                    })
                                                                }>
                                                                    <Link                                                                     
                                                                    to={item.path} 
                                                                    className="kt-menu__link kt-menu__toggle"
                                                                    onClick={(() => {
                                                                        this.props.setUIdefault();
                                                                        this.setState({ activeSub: null });
                                                                    })} 
                                                                    >
                                                                        <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
                                                                            <span></span>
                                                                        </i>
                                                                        <span className="kt-menu__link-text" style={{ minWidth: '110px'} }>{item.title}</span>
                                                                    </Link>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            )
                                        }
                                    </li>
                                ))
                            }

                            {this.props.UImobile.openSidebar &&
                                <>
                                <li className="kt-menu__section">
                                    <h4 className="kt-menu__section-text">Аккаунт</h4>
                                    <i className="kt-menu__section-icon flaticon-more-v2"></i>
                                </li>

                                <div className='kt-menu__item username'>
                                    <span className="kt-menu__link-text" style={{ minWidth: '110px'} }>{this.props.userInfo && this.props.userInfo.username}</span>
                                </div>

                                <div className='kt-menu__item'>
                                    <button 
                                    className="kt-menu__link"
                                    style={{ backgroundColor: '#00000000', outline: 'none', border: 'none'}}
                                    onClick={this.singOut}
                                    >
                                        <Icon/>
                                        <span className="kt-menu__link-text" style={{ minWidth: '110px'} }>Выход</span>
                                    </button>
                                </div>
                            </>
                            }
                            
                        </ul>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    userInfo: state.user.info,
    UImobile: state.UImobile
});

const mapDispatchToProps = {
    setUIdefault: () => setUIdefault(),
    setUIOpenSidebar: (value) => setUIOpenSidebar(value)
  }
  

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));