import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { addBlockedPhone, updateBlockedPhone, deleteBlockedPhoneById } from '../../action/handbook';
import Input from '../utils/Input';

const TableBlockedPhones = ({ items, onDelete }) => {
    const dispatch = useDispatch();
    const [editedItems, setEditedItems] = useState([]);
    
    useEffect(() => {
        setEditedItems([{ phone: '', comment: '' }, ...items]);
    }, [items]);
    
    const handleChange = useCallback((index, field, value) => {
        setEditedItems(prevItems => {
            const updatedItems = [...prevItems];
            updatedItems[index] = {
                ...updatedItems[index],
                [field]: value
            };
            return updatedItems;
        });
    }, []);

    const handleSave = useCallback(async (item) => {
        if (!item.phone && !item.comment) return;
            try {
                if (item.id) {
                    await dispatch(updateBlockedPhone(item.id, item));
            } else {
                await dispatch(addBlockedPhone(item));
            }
        } catch (error) {
            console.error('Ошибка при сохранении записи:', error);
        }
    }, [dispatch]);
    
    const handleDelete = useCallback(async (id) => {
        if (!id) return;
        
        const willDelete = await swal({
            title: "Удаление номера",
            text: "Вы действительно хотите удалить номер?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        });
        
        if (willDelete) {
            if (onDelete) {
                onDelete(id);
                setEditedItems(prev => prev.filter(item => item.id !== id));
            } else {
                try {
                    await dispatch(deleteBlockedPhoneById(id));
                    setEditedItems(prev => prev.filter(item => item.id !== id));
                } catch (error) {
                    console.error('Ошибка при удалении записи:', error);
                }
            }
        }
    }, [onDelete, dispatch]);
    
    const tableRows = useMemo(() => {
        return editedItems.map((item, index) => (
            <tr key={item.id || `new-${index}`} className="kt-datatable__row">
                <td className="kt-datatable__cell blockedphones_name">
                    <Input 
                        value={item.phone || ''} 
                        onChange={(e) => handleChange(index, 'phone', e.target.value)} 
                    />
                </td>
                <td className="kt-datatable__cell blockedphones_comment">
                    <Input 
                        value={item.comment || ''} 
                        onChange={(e) => handleChange(index, 'comment', e.target.value)} 
                    />
                </td>
                <td className="kt-datatable__cell" style={{ display: 'flex'}}>
                    <button
                        title="Сохранить изменения"
                        type="button"
                        onClick={() => handleSave(item)}
                        disabled={!item.phone}
                        className="btn btn-outline-secondary btn-sm btn-icon"
                    >
                        <i className="flaticon2-check-mark"></i>                                                
                    </button>
                    <button
                        title="Удалить"
                        type="button"
                        onClick={() => handleDelete(item.id)}
                        disabled={!item.id}
                        className="btn btn-outline-secondary btn-sm btn-icon"
                    >
                        <i className="flaticon2-trash"></i>                                                
                    </button>
                </td>
            </tr>
        ));
    }, [editedItems, handleChange, handleSave, handleDelete]);

    return (
        <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
            <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                    <th className="kt-datatable__cell blockedphones_name">Телефон</th>
                    <th className="kt-datatable__cell blockedphones_comment">Комментарий</th>
                    <th className="kt-datatable__cell">Действия</th>
                </tr>
            </thead>
            <tbody className="kt-datatable__body">
                {tableRows}
            </tbody>
        </table>
    );
};

export default React.memo(TableBlockedPhones);
