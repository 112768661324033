import React from 'react';
import { withRouter  } from 'react-router-dom';
import moment from 'moment';
import swal from 'sweetalert';
import { getFileUrl } from '../../utils/common';
import ActionMenu from '../../components/utils/ActionMenu';
import { useSelector } from 'react-redux';
import { userRoleSelector } from '../../selectors';
import { deleteDocumentation } from '../../action';
import { useDispatch } from 'react-redux';
import { roleId } from '../../constants/app';

function DocumentationList({ items, history }) {
    const role = useSelector(userRoleSelector);
    const dispatch = useDispatch();

    const renderThead = () => {
        return (
            <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                    <th className="kt-datatable__cell" width="10px">
                        <span>Файл</span>
                    </th>
                    <th className="kt-datatable__cell" width="300px">
                        <span>Заголовок</span>
                    </th>
                    <th className="kt-datatable__cell" width="200px">
                        <span>Дата</span>
                    </th>
                    <th className="kt-datatable__cell" width="auto">
                        <span>Описание</span>
                    </th>
                    {[4].includes(role) && 
                        <th className="kt-datatable__cell" width="80px">
                            <span>Действия</span>
                        </th>
                    }
                </tr>
            </thead>
        );
    }

    const onDelete = (id) => {
        swal({
            title: `Удаление документа`,
            text: `Вы действительно хотите удалить документ #${id}?`,
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteDocumentation(id));
            }
        });
    }

    const handleDoubleClick = (item) => () => {
        history.push(`/documentation/${item.id}`)
    };

    const downloadFile = (file) => {
        const element = document.createElement("a");
        element.href = getFileUrl(file);
        element.download = file.name;
        element.click();
      };

    const renderBody = () => {
        return (
            <tbody className="kt-datatable__body">
                {
                   items.length === 0 && (
                        <tr className="kt-datatable__row">
                            <td className="kt-datatable__cell">Документов пока нет</td>
                        </tr>
                    )
                }
                {
                    items.map(item => {
                        const actions = [
                            { access: roleId.admin, name: 'Редактирование', icon: 'flaticon2-contract', action: () => history.push(`/documentation/${item.id}`) },
                            { access: roleId.admin, name: 'Удалить', icon: 'flaticon2-trash', action: () => onDelete(item.id) }
                        ].filter(item => !item.access || item.access === role);

                        return (
                        <tr onDoubleClick={handleDoubleClick(item)} key={item.id} className="kt-datatable__row baseline-row">
                            <td className="kt-datatable__cell" width="10px">
                                <button title="Скачать" type="button" onClick={() => downloadFile(item.file)} disabled={item.file ? false : true} className="btn btn-outline-secondary btn-sm btn-icon ">
                                    <i className="flaticon2-download-2"></i>
                                </button>
                            </td>
                            <td className="kt-datatable__cell" width="300px">
                                {item.title}
                            </td>
                            <td className="kt-datatable__cell" width="200px">
                                {moment(item.created_at).format('DD.MM.YYYY HH:mm')}
                            </td>
                            <td className="kt-datatable__cell" width="auto">
                                {item.description}
                            </td>
                            {[roleId.admin].includes(role) && 
                                <td className="kt-datatable__cell" align='right' width="80px">
                                    <ActionMenu actions={actions}/>
                                </td>
                            }
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    const renderBodyMobile = () => {
        return(
            <tbody className="kt-datatable__body">
                {
                   items.length === 0 && (
                        <tr className="kt-datatable__row">
                            <td className="kt-datatable__cell">Документов пока нет</td>
                        </tr>
                    )
                }
                {
                    items.map(item => {
                        const actions = [
                            { access: roleId.admin, name: 'Редактирование', icon: 'flaticon2-contract', action: () => history.push(`/documentation/${item.id}`) },
                            { access: roleId.admin, name: 'Удалить', icon: 'flaticon2-trash', action: () => onDelete(item.id) }
                        ].filter(item => !item.access || item.access === role);

                        return (
                        <tr onDoubleClick={handleDoubleClick(item)} key={item.id} className="kt-datatable__row baseline-row">
                            <div className='mobile_div_flex column padding10px'>
                                <div className='mobile_div_flex gap0px'>
                                    <div style={{ width: '50%'}}>
                                        Заголовок:
                                    </div>
                                    <div className='mobile_div_flex wrap' style={{ width: '50%', color: '#000' }}>
                                        {item.title}
                                    </div>                                    
                                </div>
                                <div className='mobile_div_flex gap0px'>
                                    <div style={{ width: '50%'}}>
                                        Дата:
                                    </div>
                                    <div style={{ width: '50%', color: '#000' }}>
                                        {moment(item.created_at).format('DD.MM.YYYY HH:mm')}
                                    </div>                                    
                                </div>
                                <div className='mobile_div_flex gap0px'>
                                    <div style={{ width: '50%'}}>
                                        Описание:
                                    </div>
                                    <div className='mobile_div_flex wrap' style={{ width: '50%', color: '#000' }}>
                                        {item.description}
                                    </div>                                    
                                </div>
                                <div className='mobile_div_flex gap0px'>
                                    <div className='mobile_div_flex' style={{ width: '50%', justifyContent: 'flex-start'}}>
                                        <button title="Скачать" type="button" onClick={() => downloadFile(item.file)} disabled={item.file ? false : true} className="btn btn-outline-secondary btn-sm btn-icon ">
                                            <i className="flaticon2-download-2"></i>
                                        </button>
                                    </div>
                                    {[roleId.admin].includes(role) && 
                                    <div className='mobile_div_flex' style={{ width: '50%', justifyContent: 'flex-start'}}>
                                        <ActionMenu actions={actions}/>
                                    </div>
                                    }
                                </div>                                
                            </div>           
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }
    
    if(window.innerWidth < 800)
        return(
            <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                {renderBodyMobile()}
            </table>
        );

    return (
        <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
            {renderThead()}
            {renderBody()}
        </table>
    );

}

export default withRouter(DocumentationList);