import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { downloadRobovoiceCallByCallId } from '../../action/orders';

const RobovoiceCallDownloadButton = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loaders.downloadRobovoiceCallByCall);
  const robovoiceCallId = useSelector((state) => state.orders.detail && state.orders.detail.robovoiceCallId);

  if (!robovoiceCallId) {
    return null;
  }

  const handleDownload = () => {
    dispatch(downloadRobovoiceCallByCallId(robovoiceCallId));
  };

  return (
    <div>
      <button 
        className="order_position_list_footer_button" 
        onClick={handleDownload} 
        disabled={isLoading}
      >
          <i className="flaticon2-download-symbol"/>
      </button>
    </div>
  );
};

export default RobovoiceCallDownloadButton;