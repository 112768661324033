import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { fetchCity, fetchZones, updateCity, deleteFilial, deleteZone, updateZone } from '../../action/handbook';
import { setError } from '../../action/errors';
import ActionMenu from '../utils/ActionMenu';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Loader from '../utils/Loader';
import ErrorContainer from '../utils/ErrorContainer';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { roleId } from '../../constants/app';
import { setUIvisible } from '../../action/UImobile';
import cn from 'classnames';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class DetailCity extends Component {
    componentDidMount() {
        this.props.fetchCity(this.props.match.params.id);
        this.props.fetchZones(this.props.match.params.id);
        this.props.setUIvisible(false, false);
    }

    renderSubHeader() {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">{this.props.city.name}</h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                
                            </span>
                        </div>
                    </div>
                    <div className="kt-subheader__toolbar">
                        <button
                            onClick={() => this.props.history.push('/handbook/cities')}
                            className="btn btn-default btn-bold">
                            Назад
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    deleteFilial = (filial) => {
        swal({
            title: "Удаление филиала",
            text: "Вы действительно хотите удалить филиал?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                deleteFilial(filial).then(result => {
                    if (result) {
                        this.props.fetchCity(this.props.match.params.id);
                    } else {
                        this.props.setError({ status: 507 }, 'filials');
                    }
                })
            }
        });
    }

    deleteZone = (zone) => {
        swal({
            title: "Удаление зоны",
            text: "Вы действительно хотите удалить зону?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                deleteZone(zone).then(result => {
                    if (result) {
                        this.props.fetchCity(this.props.match.params.id);
                        this.props.fetchZones(this.props.match.params.id);
                    } else {
                        this.props.setError({ status: 507 }, 'filials');
                    }
                })
            }
        });
    }

    changeActivateZone(zone){
        const data = {...zone};

        if(data.activate !== 'disabled') {
            data.activate = 'disabled';
        } else {
            data.activate = 'active';
        }

        this.props.updateZone(data.id, data).then(result => {
            if (result) {
                this.props.fetchZones(this.props.match.params.id);
            }
        });
    }

    renderMap = () => {
        return (
            <YMaps
                style={{ padding: '25px ', marginTop: -30 }}
                query={{
                    apikey: '71140458-f614-4101-ad58-f75c79f0f6fe'
                }}>
                <Map
                    instanceRef={ref => {
                        if (ref) {
                            ref.behaviors.disable('drag');
                            ref.behaviors.disable('scrollZoom');
                            
                        } 
                    }}
                    width="100%"
                    defaultState={{
                        center: this.props.city.coord.split(','),
                        zoom: 11
                    }}
                >
                    <Placemark geometry={this.props.city.coord.split(',')} />
                </Map>
            </YMaps>
        );
    }

    renderFilials() {
        const { points } = this.props.city;

        return (
            <div className="row">
                <div className="col-xl-12">
                <div className="kt-portlet kt-portlet--height-fluid">
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                                Филиалы
                            </h3>
                        </div>
                        {
                            this.props.role.id === roleId.admin && (
                                <div className="kt-portlet__head-toolbar">
                                    <Link
                                        to={{
                                            pathname: `/handbook/filials/add/`,
                                            state: {
                                                city: this.props.city.id,
                                                backTo: `/handbook/cities/${this.props.city.id}`
                                            }
                                        }}
                                        className="btn btn-label-brand btn-bold btn-sm">
                                        Добавить филиал
                                    </Link>
                                </div>
                            )
                        }
                        
                    </div>
                    <div className="kt-portlet__body" style={{ maxHeight:300, overflow: 'auto' }}>
                        <div className="kt-widget4">
                            {
                                points.length ? points.map(item => {
                                    const actions = [
                                        { name: 'Удалить', icon: 'flaticon2-trash', action: () => this.deleteFilial(item) }
                                    ];
                                    return (
                                        <div key={item.id} className="kt-widget4__item">
                                            <span className="kt-widget4__icon">
                                                <i className="flaticon-placeholder kt-font-info"></i>
                                            </span>
                                            <Link to={`/handbook/filials/${item.id}`} className="kt-widget4__title kt-widget4__title--light">
                                                {this.renderAdress(item)}
                                            </Link>
                                            {
                                                this.props.role.id === roleId.admin && (
                                                    <ActionMenu actions={actions}/>
                                                )
                                            }
                                                
                                            
                                        </div>
                                    )
                                }) : 'Нет филиалов'
                            }
                        </div>

                    </div>
                </div>
            </div>
            </div>
    
        );
    }

    renderZone() {
        const { zones, city } = this.props;

        return (
            <div className="row">
                <div className="col-xl-12">
                <div className="kt-portlet kt-portlet--height-fluid">
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                                Зоны доставки
                            </h3>
                        </div>
                        {
                            this.props.role.id === roleId.admin && (
                                <div className="kt-portlet__head-toolbar">
                                    <Link
                                        to={{
                                            pathname: `/handbook/zone/add/`,
                                            state: {
                                                city: this.props.city.id,
                                                backTo: `/handbook/cities/${this.props.city.id}`
                                            }
                                        }}
                                        className="btn btn-label-brand btn-bold btn-sm">
                                        Добавить зону
                                    </Link>
                                </div>
                            )
                        }
                        
                    </div>
                    <div className="kt-portlet__body" style={{ maxHeight:300, overflow: 'auto' }}>
                    <div className="kt-widget4">
                            {
                                zones.length ? zones.map(item => {
                                    const actions = [
                                        { access: roleId.admin, name: 'Редактирование', icon: 'flaticon2-contract', action: () => this.props.history.push(`/handbook/zone/edit/${+item.id}`), state: { city: city.id } },
                                        { name: 'Удалить', icon: 'flaticon2-trash', action: () => this.deleteZone(item) }
                                    ];
                                    return (
                                        <div key={item.id} className="kt-widget4__item">
                                            <span className="kt-widget4__icon">
                                                <i className="flaticon-placeholder kt-font-info"></i>
                                            </span>
                                            <div className="kt-widget4__title">
                                                <b>{item.name}:</b> {item.deliveryCode ? `Код - ${item.deliveryCode} | `: ''}Стомость доставки - { item.deliveryPrice} руб. | Бесплатно от - { item.minDeliveryPrice} руб. | Время доставки - { item.deliveryTime} мин. | Точка - {this.renderAdress(item.point)}
                                            </div>
                                            {
                                                this.props.role.id === roleId.admin && (
                                                    <div style={{ display: 'flex', gap: '10px' }}>
                                                    <button
                                                        onClick={() => this.changeActivateZone(item)}
                                                        data-tip={item.activate !== 'disabled' ? 'Выключить' : 'Включить'}
                                                        disabled={this.props.editZoneLoader || this.props.loadingZones || this.props.editCityLoader}
                                                        className={cn("btn btn-sm btn-icon btn-icon-md", {
                                                            'btn-danger': item.activate === 'disabled',
                                                            'btn-success': item.activate !== 'disabled',
                                                            'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--light': this.props.editZoneLoader
                                                        })}
                                                        style={{ minWidth: '30px' }}
                                                    >
                                                        {!this.props.editZoneLoader && <i className="la la-power-off"></i>}
                                                    </button>
                                                    <ActionMenu actions={actions}/>
                                                    </div>
                                                )
                                            }
                                                
                                            
                                        </div>
                                    )
                                }) : 'Нет зон'
                            }
                        </div>
                    </div>
                </div>
            </div>
            </div>
    
        );
    } 


    renderAdress(item) {
        return `${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
    }

    render() {
        const { city, editCityLoader, loadingZones } = this.props;

        if (this.props.loading || !city.name || editCityLoader || loadingZones) {
            return (
                <div style={styles.loading}><Loader/></div>
            );
        }
        
        return (
            <Fragment>
                {this.renderSubHeader()}
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <ErrorContainer field="filials" container={true} hasCloseButton={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="kt-portlet">
                                <div className="kt-portlet__head">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">
                                           Детальная информация
                                        </h3>
                                    </div>
                                    <div className="kt-portlet__head-toolbar">
                                        <i className="flaticon2-gear"></i>
                                    </div>
                                </div>
                                <div className="kt-form kt-form--label-right">
                                    <div className="kt-portlet__body">
                                        <div className="form-group form-group-xs row">
                                            <label className="col-6 col-form-label">Город:</label>
                                            <div className="col-6">
                                                <span className="form-control-plaintext kt-font-bolder">{city.name}</span>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-xs row">
                                            <label className="col-6 col-form-label">ID города:</label>
                                            <div className="col-6">
                                                <span className="form-control-plaintext kt-font-bolder">{city.article}</span>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group form-group-xs row">
                                            <label className="col-6 col-form-label">Координаты:</label>
                                            <div className="col-6">
                                                <span className="form-control-plaintext kt-font-bolder">{city.coord}</span>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-xs row">
                                            <label className="col-6 col-form-label">Время доставки:</label>
                                            <div className="col-6">
                                                <span className="form-control-plaintext kt-font-bolder">
                                                    {city.deliveryTime}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-xs row">
                                            <label className="col-6 col-form-label">Время работы доставки:</label>
                                            <div className="col-6">
                                                <span className="form-control-plaintext kt-font-bolder">
                                                    { city.deliveryWork }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-xs row">
                                            <label className="col-6 col-form-label">Стоимость доставки:</label>
                                            <div className="col-6">
                                                <span className="form-control-plaintext kt-font-bolder">
                                                    { city.deliveryCost } руб.
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-xs row">
                                            <label className="col-6 col-form-label">Бесплатная доставка от:</label>
                                            <div className="col-6">
                                                <span className="form-control-plaintext kt-font-bolder">
                                                    { city.freeDelivery } руб.
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-xs row">
                                            <label className="col-6 col-form-label">Подарок от суммы:</label>
                                            <div className="col-6">
                                                <span className="form-control-plaintext kt-font-bolder">
                                                    { city.gift } руб.
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-xs row">
                                            <label className="col-6 col-form-label">Минимальная сумма заказа:</label>
                                            <div className="col-6">
                                                <span className="form-control-plaintext kt-font-bolder">
                                                    { city.minOrder } руб.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.props.role.id === roleId.admin && (
                                            <div className="kt-portlet__foot">
                                                <div className="kt-form__actions kt-space-between">
                                                    <Link to={`/handbook/cities/edit/${city.id}`} className="btn btn-label-brand btn-sm btn-bold">Редактировать</Link>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            {this.renderMap()}
                        </div>

                        <div className="col-xl-8">
                            {
                                this.renderFilials()
                            }
                            {
                                this.renderZone()
                            }
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    city: state.handbook.city.detail,
    zones: state.handbook.zones,
    loading: state.loaders.city,
    loadingZones: state.loaders.zones,
    editZoneLoader: state.loaders.editZone,
    editCityLoader: state.loaders.editCity,
    role: state.user.info.role
});

const mapDispatchToProps = {
    fetchCity: (id) => fetchCity(id),
    fetchZones: (id) => fetchZones(id),
    updateCity: (id, data) => updateCity(id, data),
    deleteFilial: (item) => deleteFilial(item),
    setError: (code, field) => setError(code, field),
    updateZone: (id, data) => updateZone(id, data),
    setUIvisible: (visibleFiltersButton, visibleActionButton) => setUIvisible(visibleFiltersButton, visibleActionButton)
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailCity);